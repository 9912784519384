<template>
  <NuxtLoadingIndicator color="black" :throttle="0" :height="6" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const { getWishlistProducts } = useWishlist()
const { refreshCart } = useCart()

const { init } = useInit()

// Shopware prefix
provide('urlPrefix', runtimeConfig.public.SHOP_PREFIX)

// notifications won't work without initializing them first
useNotifications()

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

useHead({
  titleTemplate: (title) =>
    title
      ? `${process.dev ? '🚧 ' : ''}${title} - JP Rosselet Cosmetics`
      : `${process.dev ? '🚧 ' : ''}JP Rosselet Cosmetics`,
})

await init()

onMounted(() => {
  if (import.meta.client) {
    getWishlistProducts()
    refreshCart()
  }
})
</script>
