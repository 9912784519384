export const useInit = () => {
  const { loadGlobals, loadNavigation } = useGlobals()
  const { locale, localeProperties } = useI18n()
  const { apiClient } = useShopwareContext()
  const { refreshSessionContext } = useSessionContext()
  const { changeLanguage } = useInternationalization()

  const cms = useCmsGlobals()
  const shopNavigation = useShopNavigation()

  const cmsGlobalsPromise = useAsyncData('globals', loadGlobals)
  const shopNavigationPromise = useAsyncData('shopNavigation', loadNavigation)

  const init = async () => {
    const { data: cmsGlobalsData } = await cmsGlobalsPromise
    cms.value = cmsGlobalsData.value || {}

    const { data: shopNavigationData } = await shopNavigationPromise
    shopNavigation.value = shopNavigationData.value

    await refreshSessionContext()
  }

  watch(
    locale,
    async () => {
      const languageId = localeProperties.value.languageId
      await changeLanguage(languageId)
      apiClient.defaultHeaders.apply({
        'sw-language-id': languageId,
      })
      shopNavigation.value = await loadNavigation()
    },
    { immediate: true }
  )

  return {
    init,
  }
}
